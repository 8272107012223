<template>
  <div>
    <b-link class="nav-link reply-count position-relative text-left" @click="getReplyComments(itemId)">
      {{ reply_count }} Replies
    </b-link>
    <div class="cmtnest-reply">
      <div class="reply-post mt-75">
        <div
          v-for="(comment, index) in replies"
          :key="index"
          class="nested-reply mt-50"
        >
        <div class="d-flex align-items-end">
          <div class="d-flex align-items-start w-100">
            <b-avatar :src="comment.user.avatar" size="34" class="mr-75" />
            <div class="mt-05 w-100" v-if="!editComments.includes(comment.hashid)">
              <div class="profile-user-info w-100 right-cmt">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="profile-user-info">
                    <h6 class="mb-0">
                      {{ comment.user.name }}
                    </h6>
                    <small class="text-secondary">
                      {{ comment.user.designation }}
                    </small>
                  </div>
                  <div class="d-flex flex-column flex-lg-row align-items-lg-center">
                    <span class="small mr-lg-50">
                      {{ comment.created_at }}
                    </span>
                    <div class="d-none d-lg-block">
                        <!-- <b-link
                            class="text-body text-body btn btn-icon rounded-circle  p-50"
                            v-b-tooltip.html.hover="'Liked by ' + getNames(comment.likes)"
                            @click="likeComment(comment.hashid, itemId)"
                        >
                            <feather-icon
                                icon="HeartIcon"
                                size="18"
                                :class="comment.youLiked ? 'profile-likes' : 'profile-icon'"
                            />
                            <span
                                v-if="comment.likes.length"
                                class="text-muted align-middle"
                            >
                        {{ comment.likes.length }}
                      </span>
                        </b-link> -->
                        <b-link
                            v-if="comment.user.hashid == userData.hashid"
                            class="text-primary btn btn-icon rounded-circle  p-50"
                            @click="editComments.pop(), editComments.push(comment.hashid)"
                        >
                            <feather-icon icon="EditIcon" size="18" />
                        </b-link>
                        <b-link
                            v-if="comment.user.hashid == userData.hashid"
                            class="text-danger btn btn-icon rounded-circle p-50"
                            @click="
                            deleteConfirm(comment, modelId)
                      "
                        >
                            <feather-icon icon="TrashIcon" size="18" class="mr-lg-25" />
                        </b-link>
                    </div>
                  </div>
                </div>
                <div class="mt-75 blog-description"  v-html="comment.comment"></div>
                  <div class="d-flex d-lg-none">
                      <!-- <b-link
                          class="text-body text-body btn btn-icon rounded-circle pl-0  pt-50 pb-50 pr-50"
                          v-b-tooltip.html.hover="'Liked by ' + getNames(comment.likes)"
                          @click="likeComment(comment.hashid, itemId)"
                      >
                          <feather-icon
                              icon="HeartIcon"
                              size="18"
                              :class="comment.youLiked ? 'profile-likes' : 'profile-icon'"
                          />
                          <span
                              v-if="comment.likes.length"
                              class="text-muted align-middle"
                          >
                        {{ comment.likes.length }}
                      </span>
                      </b-link> -->
                      <b-link
                          v-if="comment.user.hashid == userData.hashid"
                          class="text-primary btn btn-icon rounded-circle  p-50"
                          @click="editComments.pop(), editComments.push(comment.hashid)"
                      >
                          <feather-icon icon="EditIcon" size="18" />
                      </b-link>
                      <b-link
                          v-if="comment.user.hashid == userData.hashid"
                          class="text-danger btn btn-icon rounded-circle p-50"
                          @click="
                          deleteConfirm(comment,  modelId)
                      "
                      >
                          <feather-icon icon="TrashIcon" size="18" class="mr-lg-25" />
                      </b-link>
                  </div>
                <!-- <div>
                <b-link class="nav-link">
                  <feather-icon icon="CornerUpLeftIcon" size="18" class="mr-25" />
                  Reply
                </b-link>
              </div> -->
              </div>
            </div>
            <div class="w-100 cmt-posts d-flex" 
              v-else="editComments.includes(comment.hashid)">
              <div
                class="
                  border
                  p-751
                  commentBox
                  w-100
                "
              >
                <CommentBox
                  placeholder="Add a comment..."
                  className="w-100 comment-box"
                  :id="'edit-comment-field-' + comment.hashid"
                  :value="comment.comment"
                  :toolbar="false"
                  :mentionList="mentionList"
                />
                <div class="cmd-editbtn">
                    <b-button variant="outline-secondary" v-if="editComments.includes(comment.hashid)"
                      class="blog-btn-block search-post m-0" @click="editComments.pop()">
                      Cancel
                    </b-button>
                    <b-button variant="primary" @click="editComment(comment, modelId)" pill
                      class="blog-btn-block search-post m-0" :disabled="commentSubmitClicked">
                      Save
                    </b-button>
                  </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import CommentBox from "@/components/CommentBox.vue";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
import $ from "jquery";
Vue.use(VueCookies);

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    CommentBox,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    itemId: {
      type: String,
      required: false,
      default: "",
    },
    modelId: {
      type: String,
      required: false,
      default: "",
    },
    reply_count: {
      type: Number,
      required: true,
      default: 0,
    },
    mentionList: {
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      data_local: {
        channel_type: "community",
      },
      customFields: [],
      data: [],
      replies: [],
      userData: this.$cookies.get("userData"),
      editComments: [],
      employeeListOptions: [],
      commentSubmitClicked:false,
    };
  },
  setup(data_local, myFiles) {
    const USER_APP_STORE_MODULE_NAME = "app-posts";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      // store.registerModule(USER_APP_STORE_MODULE_NAME, ticketStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  computed: {},
  watch: {
    sidebarActive(val) {
      this.data_local = {
        channel_type: "community",
      };
      this.myFiles = [];
      if (this.itemId) {
        this.data_local = this.itemData;
        this.data_local.category_id = this.itemData.support_category.hashid;
      }
    },
  },
  created() {
    // this.fetchEmployeeList();
  },
  mounted() {},
  methods: {
    editComment(comment, okr_id) {
      this.commentSubmitClicked=true;
      const self = this;
      const message = document.getElementById(`edit-comment-field-${comment.hashid}`).value;
      if (message === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Reply cannot be empty",
            icon: "BellIcon",
            text: "Please enter the comments",
            variant: "danger",
          },
        });

        this.commentSubmitClicked=false;
        return false;
      }

      let params = {};
      params = {
        comment: message,
      };

      this.$http
        .patch(`okr/comment/${comment.hashid}`, params)
        .then((res) => {
          this.editComments.pop();
          this.getReplyComments(this.itemId);

          this.comment_id = null,
          document.getElementById(`edit-comment-field-${comment.hashid}`).value = "";
          $(".ql-editor").html("");
          $('#edit-cmd').hide();
          this.commentSubmitClicked=false;
        })
        .catch((error) => {
          console.log(error);
          this.commentSubmitClicked=false;
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Comment adding Failed",
                      icon: "BellIcon",
                      text: values[0][0],
                      variant: "danger",
                    },
                  });
                }
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Comment adding Failed",
                icon: "BellIcon",
                text: "Oops! Something Went Wrong",
                variant: "danger",
              },
            });
          }
        });
      return true;
    },
    fetchEmployeeList() {
      const self = this;
      let data = {};
      data.url = "list/user-list-for-tag-names";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "fetch Employee List failed.",
              icon: "BellIcon",
              variant: "danger",
              text: err.toString(),
            },
          });
        });
    },
    likeComment(comment_id, itemId) {
      const self = this;
      let data = {};
      data.url = "comments/" + comment_id + "/like";
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.getReplyComments(itemId);
          // this.replies = res.data.data;
        })
        .catch((err) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "liking failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          console.error(err);
        });
    },
    getNames(users) {
      let str = "";
      users.forEach((user) => {
        str += "<br />" + user.name;
      });
      return str;
    },
    deleteConfirm(comment,okr){
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete this comment`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteComment(comment.hashid,okr);
        }
      });
    },
    deleteComment(commentId, okrId) {
      this.$http
        .delete(`/okr/${okrId}/comments/${commentId}`)
        .then((res) => {
          this.getReplyComments(this.itemId);
          this.$emit('refresh');
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Comment deleted successfully",
              icon: "BellIcon",
              variant: "success",
            },
          });
        });
    },
    getReplyComments(comment_id) {
      const self = this;
      this.isBusy = true;
      let data = {};

      data.url = "/model-comments/" + comment_id + "/replies";
      self.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.replies = res.data.data;
          this.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Custom Fields Getting Failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    openWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.flatpickr-input {
  background: whitesmoke !important;
}
</style>

<template>
  <b-sidebar
    id="view_event_sidebar"
    ref="view_event_sidebar"
    bg-variant="white"
    :sidebar-class="fullScreen ? 'modal-full' : 'modal-responsive-width'"
    class="sidebar-lg modal-responsive-width custom-popmodal"
    shadow
    backdrop
    no-header
    right
    @shown="getEventData(itemId)"
    @hidden="resetFormData()"
    @change="(val) => $emit('update:sidebarEventViewActive', val)"
    no-close-on-backdrop
  >
    <template #default="{ hide }">
      <!-- <b-link class="text-primary floating-close" @click="hide">
        <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
      </b-link> -->
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
          border-bottom
          sticky-top
        "
      >
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="d-flex align-items-center">
            <!-- avatar -->
            <b-avatar :src="event_data.user.avatar" size="50" class="mr-1" />
            <!--/ avatar -->
            <div class="profile-user-info">
              <h6 class="mb-0">
                {{ event_data.user.name }}
              </h6>
              <small class="text-muted">{{
                event_data.created_at | formatDateTime
              }}</small>
            </div>
          </div>
          <div class="d-flex align-items-center">
          <b-dropdown
          variant="link"
          class="blog-dropdown__event"
          size="sm"
          toggle-class="text-decoration-none"
          no-caret
          v-if="
            $can('event_show') || $can('event_edit') || $can('event_delete')
          "
        >
          <template v-slot:button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="text-body align-middle mr-25"
            />
          </template>
          <b-dropdown-item
            class="btn-sm p-0"
            v-if="
              !$can('event_show') &&
              !$can('event_edit') &&
              !$can('event_delete')
            "
          >
            <feather-icon icon="XOctagonIcon" size="13" class="mr-50" />
            <span>Access Required</span>
          </b-dropdown-item>

          <b-dropdown-item
            class="btn-sm p-0"
            v-b-toggle.view_event_sidebar
            @click="editRecord(event)"
            v-if="$can('event_show')"
          >
            <feather-icon icon="EyeIcon" size="13" class="mr-50" />
            <span>View</span>
          </b-dropdown-item>
          <b-dropdown-item
            class="btn-sm p-0"
            v-b-toggle.edit-event-sidebar
            @click="editRecord(event)"
            v-if="
              ($can('event_edit') &&
                event_data.user.hashid == userData.hashid) ||
              $can('Zircly Admin')
            "
          >
            <feather-icon icon="EditIcon" size="13" class="mr-50" />
            <span>Edit</span>
          </b-dropdown-item>
          <b-dropdown-item
            class="btn-sm p-0"
            @click="confirmDeleteRecord(event)"
            v-if="
              ($can('event_delete') &&
                event_data.user.hashid == userData.hashid) ||
              $can('Zircly Admin')
            "
          >
            <feather-icon icon="TrashIcon" size="13" class="mr-50" />
            <span>Delete</span>
          </b-dropdown-item>
        </b-dropdown>
        <b-link class="fullscreen-icon"  @click="fullScreen = !fullScreen">
            <b-avatar @click="fullScreen = !fullScreen" v-if="!fullScreen" square style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-default title="Maximize" :src="require('@/assets/images/icons/center-expand.svg')"  />
            <b-avatar @click="fullScreen = !fullScreen" v-else square style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-default title="Minimize" :src="require('@/assets/images/icons/right-expand.svg')"  />
          </b-link>
          </div>
        </div>

        <div>
          <!-- <b-link class="text-primary" @click="hide">
            <feather-icon icon="XIcon" size="22" class="mr-25" />
          </b-link> -->
        </div>
      </div>
      <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" @click="hide" />

      <b-card
        tag="article"
        class="mb-2 shadow-none"
        :id="`event-card-${event_data.hashid}`"
        :img-src="event_data.poster_img"
        img-height="300px"
      >
      <div class="d-flex mb-1 justify-content-between">
        <div class="pr-1 mr-1 border-right text-center">
          <p class="text-uppercase mb-0">
            {{ event_data.start_date_n_time | getMonth }}
          </p>
          <h2>
            {{ event_data.start_date_n_time | getDate }}
          </h2>
        </div>

        <div
          class="w-100"
        >
          <b-card-title class="">{{
            event_data.name | str_limit(30)
          }}</b-card-title>
          <b-card-sub-title>
            Organized by {{ event_data.user.name | str_limit(30) }}
          </b-card-sub-title>
        </div>
        
      </div>
      <b-card-text v-html="event_data.description" class="description-view mb-75"> </b-card-text>
      <!-- Event_data Date time and Meeting Link Details -->
      <div class="event_data-fullinfo short-note" v-b-toggle.view_event_data_sidebar
          @click="editRecord(event_data)">
        <div class="media1 mb-75 d-flex align-items-center pt-25">
          <div class="media-aside mr-75 align-self-center">
            <span
              class="b-avatar text-dark"
              style="width: 20px; height: 20px"
              ><span class="b-avatar-custom">
                <feather-icon size="20" icon="CalendarIcon" />
              </span>
            </span>
          </div>
          <div class="media-body">
            <!-- <h6 class="mb-0">Sat, May 25, 2020</h6> -->
            <h6 class="mb-0">{{ event_data.start_date_n_time | formatDate }}</h6>
          </div>
        </div>
        <div class="media1 mb-75 d-flex align-items-center">
          <div class="media-aside mr-75 align-self-center">
            <span
              class="b-avatar text-dark"
              style="width: 20px; height: 20px"
              ><span class="b-avatar-custom">
                <feather-icon size="20" icon="ClockIcon" />
              </span>
            </span>
          </div>
          <div class="media-body">
            <h6 class="mb-0">{{ event_data.start_date_n_time | formatTimeFormDate }} to {{ event_data.end_date_n_time | formatTimeFormDate }}</h6>
          </div>
        </div>
        <div class="media1 mb-75 d-flex align-items-center" v-if="event_data.location">
          <div class="media-aside mr-75 align-self-center">
            <span
              class="b-avatar text-dark"
              style="width: 20px; height: 20px"
              ><span class="b-avatar-custom">
                <feather-icon size="20" icon="MapPinIcon" />
              </span>
            </span>
          </div>
          <div class="media-body">
            <h6 class="mb-0">{{ event_data.location}}</h6>
          </div>
        </div>
        <div class="media1 mb-75 d-flex align-items-center" v-if="event_data.video_conferencing != 'no' && event_data.join_url">
          <div class="media-aside mr-75 align-self-center">
            <span
              class="b-avatar text-dark"
              style="width: 20px; height: 20px"
              ><span class="b-avatar-custom">
                <feather-icon icon="ExternalLinkIcon" size="20"/>
              </span>
            </span>
          </div>
          <div class="media-body">
            <b-link
            variant="info"
            target="_blank"
            class="event_data-link"
            id="copy-link"
            v-if="event_data.video_conferencing == 'zoom' && event_data.join_url"
            @click="open_join_url(event_data.join_url, event_data.hashid)"
          >
            Zoom Call
          </b-link>

          <b-link
            variant="info"
            class="event-link"
            id="recent-event-copy-link"
            v-if="event_data.video_conferencing == 'meet' && event_data.join_url"
            @click="open_join_url(event_data.join_url, event_data.hashid)"
          >
            Google Meet
          </b-link>
          <b-tooltip target="copy-link" placement="top" class="copy-tooltip">
            <!-- Copy icon code is here -->
          <b-button
            type="button"
            v-clipboard:copy="joinUrl(event_data.join_url, event_data.hashid)"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            variant="flat-secondary"
            class="bg-transparent"
          >
            <feather-icon icon="CopyIcon" /> <span class="before-copy">Click to Copy</span>
            <span class="after-copy d-none">Copied</span>
          </b-button>
          </b-tooltip>
          </div>
        </div>
        <div class="media1 mb-1 d-flex align-items-center"  v-if="event_data.video_conferencing == 'zoom'">
          <div class="media-aside mr-75 align-self-center">
            <span
              class="b-avatar"
              style="width: 20px; height: 20px"
              ><span class="b-avatar-custom">
                <b-img :src="require('@/assets/images/icons/security.svg')" width="20"/>
              </span>
            </span>
          </div>
          <div class="media-body">
            <h6 class="mb-0">{{event_data.password}}</h6>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <b-avatar-group
          size="34"
          class="avatar-icon d-none d-lg-flex mobile-avatar"
        >
          <b-avatar
            v-for="(user, i) in event_data.accepted_attendees"
            v-if="i < 5"
            :key="i"
            v-b-tooltip.hover.bottom="user.name"
            class="pull-up m-0 mobile-avatar d-none d-lg-block"
            :src="user.avatar"
          />
        </b-avatar-group>
        <b-avatar-group
          size="34"
          class="avatar-icon d-lg-none mobile-avatar"
        >
          <b-avatar
            v-for="(user, i) in event_data.accepted_attendees"
            v-if="i < 5"
            :key="i"
            v-b-tooltip.hover.bottom="user.name"
            class="pull-up m-0 mobile-avatar d-none d-lg-block"
            :src="user.avatar"
          />
        </b-avatar-group>
        <b-link
          v-if="event_data.accepted_attendees.length > 0"
          class="text-primary text-nowrap ml-50"
          v-b-modal="'show-attendees-' + event_data.hashid"
        >
          {{ event_data.accepted_attendees.length }} member attending this event.
        </b-link>
        <b-modal
          :id="'show-attendees-' + event_data.hashid"
          hide-footer
          modal-class="show-attendees"
          scrollable
          title="Attendees"
          class="text-danger"
        >
        <template #modal-header="{ close }">
            <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" @click="$bvModal.hide('show-attendees-' + event_data.hashid)"/>
        </template>
          <b-list-group
            v-if="
              event_data.accepted_attendees && event_data.accepted_attendees.length
            "
          >
            <b-list-group-item
              v-for="user in event_data.accepted_attendees"
              :key="user.hashid"
              class="d-flex align-items-center px-0"
            >
              <b-avatar :src="user.avatar" size="24" class="mr-1" />
              <div class="profile-user-info">
                <h6 class="mb-0">                      
                  {{ user.name }}
                </h6>
                <small>
                  {{ user.designation }}
                </small>
              </div>
            </b-list-group-item>
          </b-list-group>
          <b-card-text
            v-else-if="
              event_data.accepted_attendees &&
              event_data.accepted_attendees.length == 0
            "
          >
            <div class="item error text-center my-2 empty-data">
              <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50" style="background-color: transparent" />
              <h4> OH NO!</h4>
              <p class="d-block mb-1">No Records Found!</p>
            </div>
          </b-card-text>
          <b-card-text v-else-if="event_data.accepted_attendees == null">
            <div class="d-flex align-items-center">
              <strong>Loading...</strong>
              <b-spinner class="ml-auto" variant="primary" />
            </div>
          </b-card-text>
        </b-modal>
      </div>
        <div class="d-flex justify-content-between align-items-center event-goinglink py-2 my-2">
          <h6>Going?</h6>
          <div class="demo-inline-spacing">
            <b-form-group class="m-0">
              <b-form-radio-group
                v-model="event_data.isGoing"
                @change="updateIsGoing"
                :options="optionsCheckbox"
                name="buttons-1"
                buttons
                button-variant="outline-primary"
              />
            </b-form-group>
          </div>
        </div>

        <!-- <div class="d-flex align-items-center">
          <div v-if="event_data.attended_users.length > 0" class="d-black">
            Attended Users
          </div>
          <b-avatar-group size="34" class="ml-1">
            <b-avatar
              v-for="(user, i) in event_data.attended_users"
              v-if="i < 5"
              :key="'attended_users_' + i"
              v-b-tooltip.hover.bottom="user.name"
              class="pull-up m-0"
              :src="user.avatar"
            />
          </b-avatar-group>
          <b-link
            v-if="event_data.attended_users.length > 0"
            class="text-primary text-nowrap ml-50"
            v-b-tooltip.html.hover="
              'Attended by ' + getNames(event_data.attended_users)
            "
          >
            {{ event_data.attended_users.length }} member.
          </b-link>
        </div> -->

        
        <b-img
          v-if="event_data.image"
          rounded
          class="mb-25"
          :src="event_data.image"
          style=""
        />
        <!--/ event img -->

        <div
            class="mt-2 mb-2 d-flex justify-content-center justify-content-lg-start"
          v-for="(banner, banner_index) in event_data.attachments"
          :key="'banner-' + banner_index"
        >
          <b-img
            v-if="banner.type == 'image/png' || banner.type == 'image/jpeg'"
            rounded
            class="mb-25 event__responsive-img"
            height="500px"
            :src="banner.link"
            style=""
          />
        </div>

        <div
          v-for="(video, video_index) in event_data.attachments"
          :key="'video' + video_index"
        >
          
          <b-embed
            v-if="video.type && video.type.startsWith('video/') && video.link"
            type="video"
            aspect="16by9"
            controls
            autoplay="false"
            autostart="false"
            class="rounded mb-50"
          >
            <source :src="video.link" type="video/webm" />
            <source :src="video.link" type="video/mp4" />
          </b-embed>

          <embed
            class="mt-1 rounded"
            v-else-if="video.type == 'application/pdf'"
            :src="video.link"
            frameBorder="0"
            scrolling="auto"
            width="100%"
            style="min-height: 50vh"
          />
          <div
            v-else-if="video.type == 'image/png' || video.type == 'image/jpeg'"
          ></div>
        </div>
        <!--/ likes comments  share-->
        <b-overlay :show="isBusy" no-wrap> </b-overlay>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BImg,
  BEmbed,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import { kFormatter } from "@core/utils/filter";
// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import QuillInput from "@/components/QuillInput.vue";
import $ from "jquery";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BSidebar,
    BImg,
    BEmbed,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    FilePond,
    QuillInput,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  model: {
    prop: "sidebarEventViewActive",
    event: "update:sidebarEventViewActive",
  },
  props: {
    itemId: {
      type: String,
      required: false,
      default: "",
    },
    sidebarEventViewActive: {
      type: Boolean,
      required: true,
      default: true,
    },
    itemData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      fullScreen: false,
      optionsCheckbox: [
        { text: "Yes", value: "accepted" },
        { text: "No", value: "declined" },
        { text: "Maybe", value: "tentative" },
      ],
      isBusy: true,
      data_local: {
        channel_type: "community",
      },
      customFields: [],
      support_categories: [],
      priority_option: [],
      status_option: [],
      myFiles: [],
      channel_types: [],
      channel_type_select: [],
      event_data: {
        user: {},
        attendees: [],
        accepted_attendees: [],
        attended_users: [],
      },
      comment_id: null,
      userData: this.$cookies.get("userData"),
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-tickets";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      // store.registerModule(USER_APP_STORE_MODULE_NAME, ticketStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  computed: {},
  watch: {
    sidebarEventViewActive(val) {
      if (val) {
        // this.getEventData(this.itemId);
      }
      this.data_local = {
        channel_type: "community",
      };
      this.myFiles = [];
      if (this.itemId) {
        this.data_local = this.itemData;
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    getNames(users) {
      let str = "";
      users.forEach((user) => {
        str += "<br />" + user.name;
      });
      return str;
    },
    updateIsGoing() {
      const self = this;
      if (!this.itemId) {
        console.log("no itemId");
        return true;
      }

      let data = {};
      data.url = "/events/" + this.itemId + "/update-status";
      // self.event_data._method = "PATCH";
      data.params = {
        isGoing: self.event_data.isGoing,
      };
      self.isBusy = true;
      self.$store
        .dispatch("app/update", data)
        .then((res) => {
          self.isBusy = false;
        })
        .catch((error) => {
          self.isBusy = false;
          console.log(error);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Something went wrong",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    kFormatter,
    addReply(comment, hashid) {
      const self = this;
      self.comment_id = comment.hashid;
      self.reply_to = comment.user.name;
      document.getElementById("comment-field-" + hashid).focus();
    },
    removeReply() {
      const self = this;
      self.comment_id = null;
      self.reply_to = data.null;
    },
    getEventData(itemId) {
      const self = this;
      if (!itemId) {
        console.log("no itemId");
        return true;
      }

      let data = {};
      data.url = "/events/" + itemId;

      self.isBusy = true;
      self.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.event_data = res.data.data;
          self.isBusy = false;
        })
        .catch((error) => {
          self.isBusy = false;
          console.log(error);
        });
    },
    resetFormData() {
      console.log("reset");
      const self = this;
      self.event_data = {
        user: {},
        attendees: [],
        accepted_attendees: [],
        attended_users: [],
      };
    },
    openWindow(link) {
      window.open(link);
    },
    fetchItem(hashid) {
      this.$store
        .dispatch("app-tickets/fetchTicket", hashid)
        .then((res) => {
          this.data_local = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    updateEventArray(data, index) {
      self.event_data = data;
    },
  },
};
</script>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
.flatpickr-input {
  background: whitesmoke !important;
}
</style>
